@import './variables';
@import './utilities';
@import './grid';
@import './typography';

@include generate-color-classes();

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  background-color: color('secondary-beige');
  font-family: 'Work Sans';
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

svg {
  overflow: visible;
}

section {
  position: relative;

  &:not(:last-of-type) {
    margin-bottom: module-spacing('xl');
  }

  .copy {
    width: 650px;
  }

  @include breakpoint('medium', 'max') {
    .column-container [class^='column'] {
      width: 100%;
    }

    .copy {
      margin-bottom: 20vw;
    }
  }

  @include breakpoint('xlarge', 'max') {
    .copy {
      width: calc(100% / (100 / (6 * (100 / 12))));
    }
  }

  @include breakpoint('large', 'max') {
    .copy {
      width: calc(100% / (100 / (7 * (100 / 12))));
    }
  }
}

.colored-tile {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border-radius: border-radius('medium');

  &:before {
    content: '';
    float: left;
    padding-bottom: 120%;
  }

  svg {
    width: 130%;
    position: absolute;

    g {
      opacity: 0;
    }
  }
}

.copy {
  position: relative;
}

#hi {
  @include breakpoint('medium') {
    .column-container {
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
  }

  .stroke {
    width: 70%;
  }

  .key-visual {
    .cls-1 {
      fill: #13c324;
    }
    .cls-2,
    .cls-6 {
      fill: #ffaa04;
    }
    .cls-3,
    .cls-5 {
      fill: #c4c4c4;
    }
    .cls-4 {
      fill: #ffe500;
    }
    .cls-5,
    .cls-6,
    .cls-7,
    .cls-8,
    .cls-9 {
      stroke: #000;
      stroke-width: 5px;
    }
    .cls-7 {
      fill: #ff5348;
    }
    .cls-8 {
      fill: #408afb;
    }
    .cls-9 {
      fill: #f59ec0;
    }
    .cls-10 {
      fill-rule: evenodd;
    }
  }
}

#intro {
  padding-top: 150px;
  text-align: center;

  @include breakpoint('medium', 'max') {
    margin-bottom: module-spacing('m');
  }

  .column-intro {
    max-width: 1200px;
  }

  .headline-container {
    position: relative;
  }

  .logo {
    width: 150px;
    display: block;
    height: auto;
    margin: 0 auto spacing('xxl') auto;

    @include breakpoint('medium') {
      width: 200px;
      margin: 0 auto 160px auto;
    }
  }

  .stroke {
    position: absolute;
    left: 50px;
    width: 25%;
    left: 0%;
    top: -10px;

    @include breakpoint('large') {
      width: 300px;
    }

    @include breakpoint('xlarge') {
      width: 350px;
      left: 10%;
    }
  }

  .arrow,
  .calculator,
  .stars {
    display: none;
    position: absolute;
  }

  @include breakpoint('medium') {
    .arrow,
    .calculator,
    .stars {
      display: block;
    }

    .arrow {
      width: 60px;
      right: 0;
      top: -100%;
      overflow: visible;
    }

    .calculator {
      width: 5em;
      left: 20vw;
      top: 150px;

      .cls-1 {
        fill: #ffaa04;
      }

      .cls-2 {
        fill: #3e6eff;
      }

      .cls-3 {
        fill: #ff5348;
      }

      .cls-4 {
        fill: #ffe500;
      }

      .cls-5 {
        fill-rule: evenodd;
      }

      .cls-6 {
        fill: #fff;
      }
    }

    .stars {
      width: 120px;
      right: -5%;
      top: 80%;
    }
  }

  @include breakpoint('large') {
    .calculator {
      left: 0;
    }

    .stars {
      bottom: -5%;
      right: 10%;
    }

    .arrow {
      top: -60%;
      right: 12%;
    }
  }
}

#potential {
  .stroke {
    position: absolute;
    left: 0;
    top: -150px;
    width: 50%;

    @include breakpoint('medium') {
      left: 200px;
    }
  }

  .key-visual {
    .cls-1,
    .cls-13,
    .cls-5 {
      fill: #13c324;
    }
    .cls-18,
    .cls-2 {
      fill: #ffaa04;
    }
    .cls-21,
    .cls-3,
    .cls-9 {
      fill: #ff5348;
    }
    .cls-11,
    .cls-12,
    .cls-13,
    .cls-18,
    .cls-19,
    .cls-3,
    .cls-5,
    .cls-6,
    .cls-7,
    .cls-8,
    .cls-9 {
      stroke: #000;
    }
    .cls-3 {
      stroke-width: 5px;
    }
    .cls-11,
    .cls-4 {
      fill: none;
    }
    .cls-4 {
      stroke: #fff;
    }
    .cls-4,
    .cls-5,
    .cls-8 {
      stroke-width: 5px;
    }
    .cls-22,
    .cls-6 {
      fill: #ffe500;
    }
    .cls-6 {
      stroke-width: 5px;
    }
    .cls-7 {
      fill: #d868ea;
      stroke-width: 5px;
    }
    .cls-8 {
      fill: #408afb;
    }
    .cls-11,
    .cls-12,
    .cls-13,
    .cls-9 {
      stroke-width: 3px;
    }
    .cls-10 {
      fill: #f0eee8;
    }
    .cls-14 {
      fill: #f59ec0;
    }
    .cls-15,
    .cls-17 {
      fill: #fff;
    }
    .cls-16,
    .cls-17,
    .cls-19 {
      fill-rule: evenodd;
    }
    .cls-18 {
      stroke-width: 1.5px;
    }
    .cls-19 {
      stroke-linejoin: bevel;
    }
    .cls-20 {
      fill: #3e6eff;
    }
  }
}

#launch {
  .stroke {
    position: absolute;
    left: 70px;
    top: -160px;
    width: 285px;

    @include breakpoint('large') {
      top: -145px;
    }

    @include breakpoint('xlarge') {
      right: initial;
      left: 450px;
    }

    path:last-of-type {
      display: none;

      @include breakpoint('large') {
        display: block;
      }
    }
  }

  .key-visual {
    .cls-1,
    .cls-9 {
      fill: #13c324;
    }
    .cls-2 {
      fill: #ffaa04;
    }
    .cls-3 {
      fill: #ffe500;
    }
    .cls-4,
    .cls-9 {
      fill-rule: evenodd;
    }
    .cls-12,
    .cls-17,
    .cls-5 {
      fill: none;
    }
    .cls-10,
    .cls-12,
    .cls-13,
    .cls-14,
    .cls-17,
    .cls-18,
    .cls-5,
    .cls-7,
    .cls-9 {
      stroke: #000;
    }
    .cls-5,
    .cls-7,
    .cls-9 {
      stroke-width: 5px;
    }
    .cls-18,
    .cls-6,
    .cls-7 {
      fill: #fff;
    }
    .cls-8 {
      fill: #39f;
    }
    .cls-10,
    .cls-16 {
      fill: #ff5348;
    }
    .cls-10,
    .cls-12,
    .cls-13,
    .cls-14 {
      stroke-width: 3px;
    }
    .cls-11 {
      fill: #f0eee8;
    }
    .cls-14 {
      fill: #f59ec0;
    }
    .cls-15 {
      fill: #3e6eff;
    }
    .cls-17,
    .cls-18 {
      stroke-width: 2px;
    }
  }

  @include breakpoint('medium') {
    .column-container {
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
  }
}

.stroke {
  margin-bottom: 50px;
  width: 100%;

  path {
    stroke: color('primary-black');
    stroke-width: 3;
    stroke-linecap: round;
  }
}

#team {
  .team-intro {
    text-align: center;
    margin-bottom: module-spacing('s');

    @include breakpoint('medium') {
      margin-bottom: module-spacing('m');
    }
  }

  .team-members {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: module-spacing('s');

    @include breakpoint('medium') {
      margin-bottom: module-spacing('m');
    }
  }

  .team-member {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &:nth-of-type(even) {
      .team-member-avatar-wrapper {
        transform: rotate(-5deg);
      }
    }

    &:nth-of-type(odd) {
      .team-member-avatar-wrapper {
        transform: rotate(5deg);
      }
    }

    &:nth-of-type(3n) {
      .team-member-visual {
        margin-bottom: 60px;
      }

      .team-member-avatar-wrapper {
        transform: rotate(0deg) translateY(60px);
      }
    }

    @include breakpoint('large') {
      flex-direction: row;
      text-align: left;
      width: 680px;
      align-items: flex-start;

      &:nth-of-type(even) {
        transform: translateX(150px);
      }

      &:nth-of-type(odd) {
        transform: translateX(-150px);
      }
    }

    &:not(:last-of-type) {
      margin-bottom: module-spacing('s');

      @include breakpoint('medium') {
        margin-bottom: module-spacing('m');
      }
    }
  }

  .team-member-avatar-wrapper {
    width: 80%;
  }

  .team-member-visual {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 spacing('xl') 0;
    border-radius: border-radius('medium');
    width: 150px;
    height: 180px;
    flex-shrink: 0;

    @include breakpoint('large') {
      margin: 0 spacing('xxl') 0 0;
    }
  }

  .team-recruiting {
    text-align: center;
  }
}

footer {
  text-align: center;
  margin: module-spacing('m') 0 spacing('xxl') 0;
  color: color('secondary-grey-dark');
  font-size: 12px;

  @include breakpoint('small') {
    font-size: 16px;
  }

  .turkey {
    width: 80px;
    display: block;
    margin: 0 auto spacing('m') auto;
  }
}
