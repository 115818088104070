@function color($key) {
  @return map-get($colors, $key);
}

@function box-shadow($key) {
  @return map-get($box-shadows, $key);
}

@function breakpoint($key) {
  @return map-get($breakpoints, $key);
}

@function font-weight($key) {
  @return map-get($font-weights, $key);
}

@function spacing($key) {
  @return map-get($spacings, $key);
}

@function grid-margin($key) {
  @return map-get($grid-margins, $key);
}

@function transition($key) {
  @return map-get($transitions, $key);
}

@function module-spacing($key) {
  @return map-get($module-spacings, $key);
}

@function border-radius($key) {
  @return map-get($border-radius, $key);
}

@function getLastItemOfMap($map) {
  $lastItemIndex: length($map);

  @each $key, $value in $map {
    $i: index(($map), ($key $value));

    @if ($i == $lastItemIndex) {
      @return $value;
    }
  }
}

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;

  $extmods: (
    eot: '?',
    svg: '#' + str-replace($name, ' ', '_'),
  );

  $formats: (
    otf: 'opentype',
    ttf: 'truetype',
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + '.' + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

@mixin reset-img($width: 100%) {
  display: block;
  width: $width;
  height: auto;
}

@mixin generate-font-weight-classes {
  @each $key, $value in $font-weights {
    .font-weight-#{$key} {
      font-weight: $value;
    }
  }
}

@mixin generate-module-spacing-class {
  @each $name, $value in $module-spacings {
    @media (min-width: #{map-get($breakpoints, $name)}) {
      .component-spacing {
        margin-bottom: $value;
      }
    }
  }
}

@mixin generate-color-classes {
  @each $name, $value in $colors {
    .background-#{$name} {
      background-color: $value;
    }

    .fill-#{$name} {
      fill: $value;
    }

    .color-#{$name} {
      color: $value;
    }
  }
}

@mixin breakpoint($size, $direction: min, $additionalQuerySize: '', $additionalQueryDirection: min) {
  $query: createMediaQuery($size, $direction);

  @if $additionalQuerySize != '' {
    $additionalQuery: createMediaQuery($additionalQuerySize, $additionalQueryDirection);
    @if ($additionalQuery) {
      $query: $query + ' and ' + $additionalQuery;
    }
  }

  @media #{$query} {
    @content;
  }
}

@function createMediaQuery($size, $direction) {
  $breakpoint: map-get($breakpoints, $size);

  @if $direction != min {
    $direction: max;
    $breakpoint: $breakpoint - 1;
  }

  $query: '(#{$direction}-width: #{$breakpoint})';
  @return $query;
}

@mixin ie-only() {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}
