@include font-face('Work Sans', '../assets/fonts/work-sans/work-sans-v11-latin-regular', 400, normal, woff woff2 ttf eot svg);
@include font-face('Work Sans', '../assets/fonts/work-sans/work-sans-v11-latin-500', 500, normal, woff woff2 ttf eot svg);
@include font-face('Work Sans', '../assets/fonts/work-sans/work-sans-v11-latin-700', 700, normal, woff woff2 ttf eot svg);
@include font-face('GT Maru', '../assets/fonts/gt-maru/GT-Maru-Black', 900, normal, woff woff2 ttf otf);
@include font-face('GT Maru', '../assets/fonts/gt-maru/GT-Maru-Bold', 700, normal, woff woff2 ttf otf);

h1,
h2,
h3 {
  font-family: 'GT Maru';
  font-weight: 700;
  line-height: 1em;
}

h1 {
  font-size: 38px;
  line-height: 1.1em;

  @include breakpoint('medium') {
    font-size: 50px;
  }

  @include breakpoint('large') {
    font-size: 68px;
  }

  @include breakpoint('xlarge') {
    font-size: 84px;
  }
}

h2 {
  font-size: 32px;
  line-height: 1.1em;

  @include breakpoint('medium') {
    font-size: 45px;
  }

  @include breakpoint('large') {
    font-size: 56px;
  }

  & + p {
    margin-top: spacing('xl');

    @include breakpoint('large') {
      margin-top: spacing('xxl');
    }
  }
}

h3 {
  font-size: 28px;

  @include breakpoint('medium') {
    font-size: 35px;
  }

  & + p {
    margin-top: spacing('l');
  }
}

p {
  font-size: 18px;
  line-height: 1.6em;

  @include breakpoint('medium') {
    font-size: 22px;
    line-height: 1.7em;
  }

  @include breakpoint('large') {
    font-size: 25px;
  }

  & + p {
    margin-top: 20px;
  }
}

a {
  color: color('secondary-blue-dark');
  text-decoration: underline;
  text-underline-offset: 0.2em;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;

  &:visited {
    color: color('secondary-blue-dark');
  }
}
