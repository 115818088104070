.grid-container {
  position: relative;
  width: 100%;

  @each $key, $value in $grid-margins {
    @if (map-has-key($breakpoints, $key)) {
      @include breakpoint($key) {
        padding: 0 $value;
      }
    }
  }

  @media (min-width: #{$grid-max-width + (getLastItemOfMap($grid-margins) * 2)}) {
    max-width: $grid-max-width;
    margin: 0 auto;
    padding: 0;
  }
}

.column-container {
  display: flex;
  flex-wrap: wrap;

  @each $key, $value in $grid-column-gutters {
    @if (map-has-key($breakpoints, $key)) {
      @include breakpoint($key) {
        margin: 0 -#{$value / 2};
      }
    }
  }

  &.is-centered {
    justify-content: center;
  }

  &.is-space-between {
    justify-content: space-between;
  }

  &.center-items {
    align-items: center;
  }

  &.has-reduced-gutter {
    margin: 0 -#{map-get($grid-column-gutters, 'small') / 2} !important;

    [class*='column-'] {
      padding: 0 #{map-get($grid-column-gutters, 'small') / 2};
    }
  }
}

@for $i from 1 through $grid-column-count {
  .column-#{$i} {
    width: calc(100% / (100 / (#{$i} * (100 / #{$grid-column-count}))));

    @each $key, $value in $grid-column-gutters {
      @if (map-has-key($breakpoints, $key)) {
        @include breakpoint($key) {
          padding: 0 $value / 2;
        }
      }
    }
  }
}

.column-fixed-width {
  @each $key, $value in $grid-column-gutters {
    @if (map-has-key($breakpoints, $key)) {
      @include breakpoint($key) {
        padding: 0 $value / 2;
      }
    }
  }
}
